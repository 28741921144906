'use strict';
const pako = require('pako');

function colorsChanged(previousColors, newColors) {
    if (!newColors) return false;
    if (!previousColors && newColors) return true;
    for (let colorKey in previousColors) {
        if (previousColors[colorKey] != newColors[colorKey]) return true;
    }
    return false;
}

function colorSvgString(preparedSvgString, colors) {
    let coloredString = preparedSvgString;
    for (const colorKey in colors) {
        const regex = new RegExp('CC' + colorKey.slice(1), 'g');
        coloredString = coloredString.replace(regex, colors[colorKey]);
    }

    const blob = new Blob([coloredString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    return { url, string: coloredString };
}

function prepareColors(svgString) {
    svgString = svgString.replace(/"#([0-9a-fA-F]{3})"/g, '"#$1$1"');

    svgString = svgString.replace(/#([0-9a-fA-F]{6})/g, 'CC$1');
    return svgString;
}

/*  Find all hex colors in svg string except page/bordercolor and return the set of this colors */
function extractColors(svgString) {
    svgString = svgString.replace(/(page|border|desk)color="#([a-f]|\d)*/g, '');
    const results = svgString.match(/#([0-9a-fA-F]{6})/g);
    return new Set(results);
}

/* Parse width and height in svg (could also parse viewBox) */
function extractDimensions(svgString) {
    const widthMatch = svgString.match(/width="(\d*(.\d+)?(pt)?)"/);
    const heightMatch = svgString.match(/height="(\d*(.\d+)?(pt)?)"/);

    if (widthMatch != null && heightMatch != null) return { width: parseFloat(widthMatch[1]), height: parseFloat(heightMatch[1]) };

    const viewBox = svgString.match(/viewBox="0 0 (\d*\.?\d+) (\d*\.?\d+)"/);
    return { width: parseFloat(viewBox[1]), height: parseFloat(viewBox[2]) };
}

/*
  Load a svg from a svgurl (possiblity a object url). Can handle binary svg (svgz).
  Prepare the colors.
  Options: { prepareColors: true/false }
*/
function loadSvg(svgUrl, options) {
    return new Promise((resolve, reject) => {
        fetch(svgUrl).then((response) => {
            response.blob().then((blob) => {
                // In aktivisda, urls points to svg(z)
                // But in backtivisda we use blob files
                if (svgUrl.endsWith('.svgz') || (blob.name && blob.name.endsWith('.svgz'))) {
                    blob.arrayBuffer()
                        .then((buffer) => {
                            const data = new Uint8Array(buffer);
                            let svgString = pako.inflate(data, { to: 'string' });
                            if (options.prepareColors) svgString = prepareColors(svgString);
                            resolve(svgString);
                        })
                        .catch((err) => {
                            reject(err);
                        });
                } else {
                    blob.text()
                        .then((svgString) => {
                            if (options.prepareColors) svgString = prepareColors(svgString);
                            resolve(svgString);
                        })
                        .catch((err) => reject(err));
                }
            });
        });
    });
}

function svgToDom(svgString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, 'image/svg+xml');
    return doc;
}

function computeCoverSize(imageWidth, imageHeight, documentWidth, documentHeight) {
    const ratioWidth = documentWidth / imageWidth;
    const ratioHeight = documentHeight / imageHeight;
    const ratio = Math.max(ratioWidth, ratioHeight);

    const width = Math.round(ratio * imageWidth);
    const height = Math.round(ratio * imageHeight);

    return { width, height };
}

function makeHitformFunction(hitformString, scale) {
    if (hitformString === undefined) return undefined;
    const commands = [...hitformString.matchAll(/(m|M|v|V|h|H|z|Z|l|L|c|C)(( |,)-?\d*(\.\d+)?)*/g)].map((x) => x[0]);
    return function (ctx) {
        ctx.beginPath();
        let x = 0;
        let y = 0;

        // let debugPath = '';
        for (let k = 0; k < commands.length; ++k) {
            const command = commands[k][0];
            if (command === 'z' || command === 'Z') {
                // debugPath += ` Z`
                ctx.closePath();
                continue;
            }
            const numbers = [...commands[k].matchAll(/-?\d+\.?\d*e?-?\d*?/g)].map((x) => parseFloat(x[0]));
            let numberIndex = 0;
            while (numberIndex < numbers.length) {
                switch (command) {
                    case 'M': {
                        x = numbers[numberIndex];
                        ++numberIndex;
                        y = numbers[numberIndex];
                        ++numberIndex;
                        ctx.moveTo(scale * x, scale * y);
                        // debugPath += ` M ${x} ${y}`
                        break;
                    }
                    case 'm': {
                        const dx = numbers[numberIndex];
                        ++numberIndex;
                        const dy = numbers[numberIndex];
                        ++numberIndex;
                        x += dx;
                        y += dy;
                        ctx.moveTo(scale * x, scale * y);
                        // debugPath += ` m ${dx} ${dy}`
                        break;
                    }

                    case 'L': {
                        x = numbers[numberIndex];
                        ++numberIndex;
                        y = numbers[numberIndex];
                        ++numberIndex;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` L ${x} ${y}`
                        break;
                    }

                    case 'l': {
                        const dx = numbers[numberIndex];
                        ++numberIndex;
                        const dy = numbers[numberIndex];
                        ++numberIndex;
                        x += dx;
                        y += dy;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` l ${dx} ${dy}`
                        break;
                    }

                    case 'C': {
                        // cp1x, cp1y, cp2x, cp2y, x, y
                        const cp1x = numbers[numberIndex];
                        ++numberIndex;
                        const cp1y = numbers[numberIndex];
                        ++numberIndex;
                        const cp2x = numbers[numberIndex];
                        ++numberIndex;
                        const cp2y = numbers[numberIndex];
                        ++numberIndex;
                        x = numbers[numberIndex];
                        ++numberIndex;
                        y = numbers[numberIndex];
                        ++numberIndex;
                        ctx.bezierCurveTo(scale * cp1x, scale * cp1y, scale * cp2x, scale * cp2y, scale * x, scale * y);
                        // debugPath += ` C ${cp1x} ${cp1y} ${cp2x} ${cp2y} ${x} ${y}`
                        break;
                    }

                    case 'c': {
                        const dcp1x = numbers[numberIndex];
                        ++numberIndex;
                        const dcp1y = numbers[numberIndex];
                        ++numberIndex;
                        const dcp2x = numbers[numberIndex];
                        ++numberIndex;
                        const dcp2y = numbers[numberIndex];
                        ++numberIndex;
                        const dx = numbers[numberIndex];
                        ++numberIndex;
                        const dy = numbers[numberIndex];
                        ++numberIndex;
                        ctx.bezierCurveTo(
                            scale * (x + dcp1x),
                            scale * (y + dcp1y),
                            scale * (x + dcp2x),
                            scale * (y + dcp2y),
                            scale * (x + dx),
                            scale * (y + dy),
                        );
                        // debugPath += ` c ${dcp1x} ${dcp1y} ${dcp2x} ${dcp2y} ${dx} ${dy}`
                        x += dx;
                        y += dy;
                        break;
                    }

                    case 'v': {
                        const dy = numbers[numberIndex];
                        ++numberIndex;
                        y += dy;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` v ${dy}`
                        break;
                    }

                    case 'h': {
                        const dx = numbers[numberIndex];
                        ++numberIndex;
                        x += dx;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` h ${dx}`
                        break;
                    }

                    case 'V': {
                        y = numbers[numberIndex];
                        ++numberIndex;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` V ${y}`
                        break;
                    }

                    case 'H': {
                        x = numbers[numberIndex];
                        ++numberIndex;
                        ctx.lineTo(scale * x, scale * y);
                        // debugPath += ` H ${x}`
                        break;
                    }
                }
            }
        }
        ctx.fillStrokeShape(this);

        // console.log(debugPath)
        // if (numberIndex < numbers.length) {
        //     console.log('Unused numbers ', numbers.length - numberIndex)
        // }
    };
}

export { colorsChanged, colorSvgString, loadSvg, computeCoverSize, makeHitformFunction, svgToDom, extractColors, prepareColors, extractDimensions };
