<template>
    <div>
        <symbol-picker
            v-if="imageValue.type === 'internalsvg' || imageValue.type === 'urlphoto' || imageValue.type === 'internalphoto'"
            v-model="imageValue" />
        <qrcode-picker v-else-if="imageValue.type === 'qrcode'" v-model="imageValue" />
    </div>
</template>

<script>
import SymbolPicker from '@/components/pickers/SymbolPicker.vue';
import QrcodePicker from '@/components/pickers/QrcodePicker.vue';

export default {
    name: 'image-picker',
    components: { SymbolPicker, QrcodePicker },
    props: {
        value: Object,
    },
    data() {
        return {
            copyValue: this.value,
        };
    },
    computed: {
        imageValue: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                this.$emit('input', newValue);
            },
        },
    },
};
</script>

<style></style>
