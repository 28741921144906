'use strict';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
const all_langs = require('@/assets/langs.json');
const localConfig = require('@/assets/local/localconfig.json');

export const langs = all_langs.filter((lang) => localConfig['availableLangs'].indexOf(lang.code) >= 0);

Vue.use(VueI18n);

// load default locale
export const DEFAULT_LOCALE = 'en';

function selectLanguage() {
    const urlCode = window.location.pathname.replace(/^\/([^\/]+).*/i, '$1');
    if (langs.find((l) => l.code === urlCode)) return { initLocale: urlCode, isDefault: false };

    const navigatorLanguages = window.navigator.languages;
    for (let k = 0; k < navigatorLanguages.length; ++k) {
        if (langs.find((l) => l.code == navigatorLanguages[k]))
            if (langs.find((l) => l.code == navigatorLanguages[k])) return { initLocale: navigatorLanguages[k], isDefault: false };
    }
    if (!langs.map((lang) => lang.code).includes(DEFAULT_LOCALE)) return { initLocale: langs[0].code, isDefault: !window.__prerender };

    return { initLocale: DEFAULT_LOCALE, isDefault: !window.__prerender };
}

export const { initLocale, isDefault } = selectLanguage();

const loadedLanguages = [initLocale];
const localMessages = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${initLocale}.json`);
localMessages['local'] = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${initLocale}.json`);

const messages = { [initLocale]: localMessages };

if (initLocale != DEFAULT_LOCALE) {
    loadedLanguages.push(DEFAULT_LOCALE);
    const defaultMessages = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${DEFAULT_LOCALE}.json`);
    defaultMessages['local'] = require(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${DEFAULT_LOCALE}.json`);

    messages[[DEFAULT_LOCALE]] = defaultMessages;
}

export const i18n = new VueI18n({
    locale: initLocale,
    fallbackLocale: DEFAULT_LOCALE,
    silentFallbackWarn: true,
    messages: messages,
});

export const loadLanguage = async (lang) => {
    // requested lang is already the current locale
    if (i18n.locale === lang) {
        return;
    }

    // requested lang is not available
    const isLangAvailable = langs.find((l) => l.code === lang);
    if (!isLangAvailable) {
        return;
    }

    // load locale if needed
    if (!loadedLanguages.includes(lang)) {
        const messages = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/i18n/${lang}.json`);
        messages['local'] = await import(/* webpackChunkName: "i18n-[request]" */ `@/assets/local/i18n/${lang}.json`);
        i18n.setLocaleMessage(lang, messages);
        loadedLanguages.push(lang);
    }

    // set locale globally
    i18n.locale = lang;
};

// load user's preferred language
// TODO voir ce qu'on en fait
// loadLanguage(window.navigator.language.split('-')[0]);
