'use strict';

import { colorSvgString, colorsChanged, loadSvg, makeHitformFunction } from '@/vida/components/svgutils.js';

import { useStore } from '@datastore';

import ImageVidaSubComponent from './imagevidasubcomponent';
import { Canvg } from 'canvg';

export default class InternalSvgVidaSubComponent extends ImageVidaSubComponent {
    static type = 'internalsvg';

    constructor() {
        super();
        this.recomputePosition = false;
        this.recomputeImage = false;
        this.type = 'internalsvg';
    }

    static randomOptions(image_use) {
        const store = useStore();
        const element = store.randomElement(image_use);
        return {
            type: this.type,
            id: element.id,
            colors: element.colors, // todo random colors
        };
    }

    toJson() {
        return {
            type: this.type,
            id: this.id,
            colors: this.colors,
            width: this.originalSize.width,
            height: this.originalSize.height,
        };
    }

    toPdf() {
        return {
            svg: this.coloredString,
        };
    }

    update(options) {
        return new Promise((resolve, reject) => {
            if (!options) resolve();

            if (options !== undefined && (this.id === undefined || (options.id !== undefined && this.id != options.id))) {
                const store = useStore();
                const gallery = options.gallery ? options.gallery : 'images';
                const image = store.imageData(options.id, gallery);
                if (!image) {
                    const randomImageId = InternalSvgVidaSubComponent.randomOptions(gallery).id;
                    this.update({ id: randomImageId }).then((x) => resolve(x));
                }

                this.hitform = image.hitform; // maybe undefined
                this.originalSize = { width: image.width, height: image.height };

                this.colors = undefined;
                this.inPromise = true;

                store.imageFile(options.id, gallery).then((symbolUrl) => {
                    loadSvg(symbolUrl, { prepareColors: true })
                        .then((svgString) => {
                            this.svgString = svgString;
                            this.id = options.id;
                            this.inPromise = false;
                            this.update(options)
                                .then(() => resolve())
                                .catch((err) => reject(err));
                        })
                        .catch((err) => reject(err));
                });
                return;
            }

            if (options !== undefined && (!this.canvas || colorsChanged(this.colors, options.colors))) {
                this.recomputeImage = true;
                this.recomputePosition = true;
                this.colors = options.colors;
                console.assert(this.svgString);
                this.coloredString = colorSvgString(this.svgString, options.colors).string;
            }
            resolve();
        });
    }

    makeImage(imageWidth, imageHeight) {
        return new Promise((resolve, reject) => {
            if (this.inResize) reject();

            this.inResize = true;
            if (!this.canvas) this.canvas = window.document.createElement('canvas');
            const ctx = this.canvas.getContext('2d');
            try {
                this.canvgElement = Canvg.fromString(ctx, this.coloredString);
            } catch (e) {
                console.error(e);
            }

            this.canvgElement.resize(imageWidth, imageHeight, 'xMidYMid meet');
            this.canvgElement
                .render()
                .then(() => {
                    this.inResize = false;
                    resolve();
                })
                .catch((err) => {
                    console.error(err);
                    reject(err);
                });
        });
    }

    image() {
        console.assert(this.canvas);
        return this.canvas;
    }

    makeHitformFunction(ratio) {
        return makeHitformFunction(this.hitform, ratio);
    }
}
