import { defineStore } from 'pinia';

import Vida from '@/vida/vida';

export const useVidaStore = defineStore('vidaStore', {
    state: () => {
        return {
            vida: undefined,
        };
    },

    actions: {
        setup(canvasId) {
            this.vida = new Vida(canvasId);
        },
    },
});
