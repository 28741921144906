'use strict';

import AbstractVidaComponent from '@/vida/components/abstractvidacomponent.js';
import ImageVidaComponent from '@/vida/components/imagevidacomponent';

import Konva from 'konva';

const maxInt = 100000000;

export default class BackgroundVidaComponent extends AbstractVidaComponent {
    constructor(vida, id) {
        super(vida, id);
        this.backgroundComponent = new ImageVidaComponent(vida, id);
        this.inPromise = false;
    }

    select() {
        // nothing to do
    }

    unselect() {
        // nothing to do
    }

    bringClipperForward() {
        if (!this.konvaClipper.parent) return;
        this.konvaClipper.setZIndex(this.konvaClipper.parent.children.length - 1);
    }

    toJson() {
        if (!this.backgroundComponent) return {};
        return this.backgroundComponent.image.toJson();
    }

    toPdf() {
        if (!this.backgroundComponent) return undefined;
        const r = this.backgroundComponent.toPdf();
        return r;
    }

    destroy() {
        if (this.konvaGroup) this.konvaGroup.destroy();
        if (this.konvaClipper) this.konvaClipper.destroy();
    }

    sendBackward() {
        if (!this.backgroundComponent.konvaElement) return;
        if (!this.konvaGroup) return;
        this.backgroundComponent.update({ zIndex: 0 });
        this.konvaGroup.setAttrs({ zIndex: 0 });
        this.vida.draw();
    }

    internalUpdate(options, document = null) {
        return new Promise((resolve) => {
            if (this.inPromise) {
                resolve();
                return;
            }

            if (!this.konvaGroup) {
                this.konvaGroup = new Konva.Group();
                this.vida.registerTransformer(this.konvaGroup);
                this.konvaGroup.on('click', () => {
                    this.vida.selectComponent(this.id);
                });
            }

            if (!this.konvaClipper) {
                this.konvaClipper = new Konva.Group();
                this.konvaClipper.rect = new Konva.Rect({
                    x: -maxInt,
                    y: -maxInt,
                    width: 2 * maxInt,
                    height: 2 * maxInt,
                    fill: '#aeaeae',
                    listening: false,
                    opacity: 0.8,
                });
                this.konvaClipper.add(this.konvaClipper.rect);
                this.vida.registerTransformer(this.konvaClipper);
            }

            if (options.id) this.id = options.id;

            this.backgroundComponent
                .update(
                    { id: this.id, zIndex: 0, scale: 100, position: { x: 0.5, y: 0.5 }, image: { ...options.background, gallery: 'backgrounds' } },
                    document,
                )
                .then((konvaElementCreated) => {
                    if (konvaElementCreated) {
                        this.vida.registerComponent(this.backgroundComponent.konvaElement);
                        this.konvaGroup.add(this.backgroundComponent.konvaElement);
                    }

                    if (
                        konvaElementCreated ||
                        (options.width && (!this.width || this.width !== options.width)) ||
                        (options.height && (!this.height || this.height !== options.height))
                    ) {
                        if (options.width) this.width = options.width;
                        if (options.height) this.height = options.height;

                        this.konvaGroup.setAttrs({
                            clipX: 0,
                            clipY: 0,
                            zIndex: 0,
                            clipWidth: this.width,
                            clipHeight: this.height,
                        });

                        const clipX = this.width;
                        const clipY = this.height;
                        this.konvaClipper.setClipFunc(function (ctx) {
                            ctx.rect(-maxInt, -maxInt, 2 * maxInt, maxInt);
                            ctx.rect(-maxInt, -maxInt, maxInt, 2 * maxInt);
                            ctx.rect(clipX, -maxInt, maxInt, 2 * maxInt);
                            ctx.rect(-maxInt, clipY, 2 * maxInt, maxInt);
                        });
                        this.vida.draw();
                    }

                    this.bringClipperForward();
                    this.vida.draw();
                    resolve();
                });
        });
    }
}
