'use strict';

import ImageVidaSubComponent from './imagevidasubcomponent';

import { useStore } from '@datastore';

export default class PhotoSubComponent extends ImageVidaSubComponent {
    static type = 'internalphoto';

    constructor(type) {
        super();
        this.type = type;
    }

    static randomOptions() {
        throw Error('Not implemented');
    }

    toJson() {
        if (this.type === 'internalphoto') {
            return {
                type: this.type,
                id: this.id,
                width: this.originalSize.width,
                height: this.originalSize.height,
            };
        } else if (this.type === 'urlphoto') {
            return {
                type: this.type,
                url: this.photoUrl,
                width: this.originalSize.width,
                height: this.originalSize.height,
            };
        } else {
            throw Error(`toJson not implemented for type ${this.type}`);
        }
    }

    toPdf() {
        if (this.type === 'urlphoto') {
            return { image: this.photoUrl };
        }
        // todo find a way to move code from imagecomponents to here
        return {};
    }

    loadPhoto(photoUrl) {
        return new Promise((resolve) => {
            this.photoUrl = photoUrl;
            this.recomputeImage = true;
            if (this.photo) delete this.photo;
            this.photo = new Image();
            this.photo.onload = () => {
                this.originalSize = { width: this.photo.width, height: this.photo.height };
                resolve();
            };
            this.photo.src = this.photoUrl;
        });
    }

    update(options) {
        return new Promise((resolve) => {
            if (!options) resolve();

            if (options.id !== undefined && this.id !== options.id) {
                const store = useStore();
                let image = store.imageData(options.id, 'backgrounds');
                let category = image ? 'backgrounds' : 'symbols';

                if (!store.imageData(options.id, 'backgrounds') && !store.imageData(options.id, 'symbols')) {
                    const randomImageId = store.randomElement('image').id;
                    this.update({ id: randomImageId }).then((x) => resolve(x));
                    return;
                }

                this.mimeType = store.imageMimeType(options.id, category);

                this.id = options.id;
                this.originalSize = { width: 0, height: 0 };
                const self = this;
                store.imageFile(options.id, category).then((photoUrl) => {
                    self.loadPhoto(photoUrl).then(() => resolve());
                });
                return;
            } else if (options.url !== undefined && this.photoUrl !== options.url) {
                this.mimeType = 'image/jpeg'; //

                this.id = undefined;
                this.loadPhoto(options.url).then(() => resolve());
            } else {
                resolve();
                return;
            }

            return;
        });
    }

    makeImage() {
        return new Promise((resolve) => {
            this.inResize = false;
            resolve();
        });
    }

    image() {
        return this.photo;
    }

    makeHitformFunction() {
        return undefined;
    }
}
