'use strict';

import { Canvg } from 'canvg';

import { createSvgQrcode } from '@/plugins/qrcode.js';
import { colorSvgString, loadSvg, svgToDom, colorsChanged, makeHitformFunction } from '@/vida/components/svgutils.js';
import localConfig from '@/assets/local/localconfig.json';
import { useStore } from '@datastore';

import ImageVidaSubComponent from './imagevidasubcomponent';

export default class QrcodeVidaSubComponent extends ImageVidaSubComponent {
    static type = 'qrcode';

    constructor() {
        super();
        this.recomputePosition = false;
        this.recomputeImage = false;
        this.recomputeMergeSvg = false;
        this.type = 'qrcode';
    }

    static randomOptions() {
        const store = useStore();
        const randomSymbol = store.randomElement('images');

        const random_options = {
            type: this.type,
            url: localConfig.url,
            color: '#000000',
            backgroundColor: '#ffffff',
            symbol: {},
        };
        if (randomSymbol !== undefined) {
            random_options.symbol = {
                id: randomSymbol['id'],
                colors: randomSymbol['colors'],
            }
        }
        return random_options;
    }

    toJson() {
        const symbol = this.options.symbol ? { id: this.options.symbol.id, colors: this.options.symbol.colors } : {};
        return {
            type: this.type,
            url: this.options.url,
            color: this.options.color,
            backgroundColor: this.options.backgroundColor,
            symbol,
        };
    }

    toPdf() {
        return {
            svg: this.toSvg(),
        };
    }

    mergeSvg() {
        if (!this.recomputeMergeSvg) return;

        this.recomputeMergeSvg = false;
        this.originalSize = { width: 300, height: 300 };
        const resultSize = Math.min(this.originalSize.width, this.originalSize.height);

        const qrDom = svgToDom(this.baseQrcodeString);

        const symbolDom = this.symbolColoredString ? svgToDom(this.symbolColoredString) : undefined;
        let symbolGroup = undefined;
        if (symbolDom) {
            const symbolViewbox = { x: 0, y: 0, width: 0, height: 0 };
            symbolViewbox.x = symbolDom.rootElement.viewBox.baseVal.x;
            symbolViewbox.y = symbolDom.rootElement.viewBox.baseVal.y;
            symbolViewbox.width = symbolDom.rootElement.viewBox.baseVal.width;
            symbolViewbox.height = symbolDom.rootElement.viewBox.baseVal.height;

            console.assert(this.originalSize.width === this.originalSize.height);
            const symbolMaxSize = 0.5 * resultSize;

            const symbolScale = symbolMaxSize / Math.max(symbolViewbox.width, symbolViewbox.height);
            const translateX = (300 - symbolViewbox.width * symbolScale) / 2;
            const translateY = (300 - symbolViewbox.height * symbolScale) / 2;

            symbolGroup = document.createElement('g');
            symbolGroup.setAttribute('transform', `translate(${translateX}, ${translateY}) scale(${symbolScale})`);

            const symbolBackground = document.createElement('path');
            symbolBackground.setAttribute('d', this.options.symbol.backgroundHull);
            symbolBackground.setAttribute('style', `fill:${this.options.backgroundColor}`);

            symbolGroup.replaceChildren(symbolBackground, ...symbolDom.rootElement.children);
        }

        const qrcodeBackground = document.createElement('rect');
        qrcodeBackground.setAttribute('style', `fill:${this.options.backgroundColor}`);
        qrcodeBackground.setAttribute('width', this.originalSize.width);
        qrcodeBackground.setAttribute('height', this.originalSize.height);
        qrcodeBackground.setAttribute('x', '0');
        qrcodeBackground.setAttribute('y', '0');

        const qrcodeGroup = document.createElement('g');
        const qrcodeSize = qrDom.rootElement.viewBox.baseVal.width;
        qrcodeGroup.setAttribute('transform', `scale(${resultSize / qrcodeSize})`);
        qrcodeGroup.setAttribute('style', `fill:${this.options.color}`);

        qrcodeGroup.replaceChildren(...qrDom.rootElement.children);

        this.qrcodeResultDom = document.createElement('div');
        const svgns = 'http://www.w3.org/2000/svg';
        const svgResult = document.createElementNS(svgns, 'svg');
        svgResult.setAttribute('viewBox', `0 0 ${this.originalSize.width} ${this.originalSize.height}`);
        svgResult.setAttribute('width', this.originalSize.width);
        svgResult.setAttribute('height', this.originalSize.height);
        svgResult.setAttribute('style', `background-color:${this.options.backgroundColor}`);
        svgResult.setAttribute('xmlns:dc', 'http://purl.org/dc/elements/1.1/');
        svgResult.setAttribute('xmlns:cc', 'http://creativecommons.org/ns#');
        svgResult.setAttribute('xmlns:rdf', 'http://www.w3.org/1999/02/22-rdf-syntax-ns#');
        svgResult.setAttribute('xmlns:svg', 'http://www.w3.org/2000/svg');
        svgResult.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        svgResult.setAttribute('xmlns:xlink', 'http://www.w3.org/1999/xlink');
        svgResult.setAttribute('xmlns:sodipodi', 'http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd');
        svgResult.setAttribute('xmlns:inkscape', 'http://www.inkscape.org/namespaces/inkscape');
        svgResult.setAttribute('xml:space', 'preserve');
        svgResult.setAttribute('version', '1.1');

        svgResult.appendChild(qrcodeBackground);
        svgResult.appendChild(qrcodeGroup);
        if (symbolGroup) svgResult.appendChild(symbolGroup);

        this.qrcodeResultDom.appendChild(svgResult);
    }

    update(options) {
        return new Promise((resolve) => {
            if (!this.options) this.options = {};
            if (!this.options.url) this.options.url = undefined;
            if (!this.options.color) this.options.color = undefined;
            if (!this.options.backgroundColor) this.options.backgroundColor = undefined;
            if (!this.options.symbol) this.options.symbol = {};
            let recreate_qrcode = false;

            if (options && options.color && this.options.color !== options.color) {
                this.options.color = options.color;
                this.recomputeImage = true;
                this.recomputeMergeSvg = true;
            }

            if (options && options.backgroundColor && this.options.backgroundColor !== options.backgroundColor) {
                this.options.backgroundColor = options.backgroundColor;
                this.recomputeImage = true;
                this.recomputeMergeSvg = true;
            }

            if (options && options.url && options.url !== this.options.url) {
                this.options.url = options.url;
                recreate_qrcode = true;
            }

            if (recreate_qrcode) {
                createSvgQrcode(this.options.url).then((svgString) => {
                    this.baseQrcodeString = svgString;
                    this.update(options).then(() => {
                        this.recomputeImage = true;
                        this.recomputeMergeSvg = true;
                        resolve();
                    });
                });
                return;
            }

            if (
                options &&
                options.symbol &&
                Object.keys(options.symbol).length === 0 &&
                (!this.options.symbol || Object.keys(this.options.symbol).length > 0)
            ) {
                this.options.symbol = {};
                this.recomputeMergeSvg = true;
                this.symbolSvgString = undefined;
                this.symbolColoredString = undefined;
                this.recomputeImage = true;
            }

            if (options && options.symbol && options.symbol.id && options.symbol.id !== this.options.symbol.id) {
                if (this.options.symbol == undefined) this.options.symbol = {};
                this.options.symbol.id = options.symbol.id;

                const store = useStore();

                const symbol = store.imageData(options.symbol.id, 'symbols');
                this.options.symbol.backgroundHull = symbol.backgroundHull ? symbol.backgroundHull : symbol.hitform;
                this.options.symbol.colors = symbol.colors;

                store.imageFile(options.symbol.id, 'symbols').then((symbolUrl) => {
                    loadSvg(symbolUrl, { prepareColors: true }).then((svgString) => {
                        this.recomputeMergeSvg = true;
                        this.recomputeImage = true;
                        this.symbolSvgString = svgString;
                        this.symbolColoredString = colorSvgString(this.symbolSvgString, symbol.colors).string;
                        this.update().then(() => {
                            resolve();
                        });
                    });
                });
                return;
            }

            if (options && options.symbol && options.symbol.colors && colorsChanged(this.options.symbol.colors, options.symbol.colors)) {
                this.options.symbol.colors = options.symbol.colors;
                this.symbolColoredString = colorSvgString(this.symbolSvgString, options.symbol.colors).string;
                this.recomputeMergeSvg = true;
                this.recomputeImage = true;
            }
            this.originalSize = { width: 300, height: 300 };

            resolve();
        });
    }

    toSvg() {
        this.mergeSvg();
        if (!this.qrcodeResultDom) return '<svg></svg>';
        return this.qrcodeResultDom.innerHTML;
    }

    makeImage(imageWidth, imageHeight) {
        return new Promise((resolve, reject) => {
            if (this.inResize) reject();

            this.inResize = true;
            this.mergeSvg();

            if (!this.canvas) this.canvas = window.document.createElement('canvas');
            const ctx = this.canvas.getContext('2d');

            this.canvgElement = Canvg.fromString(ctx, this.toSvg());

            this.canvgElement.resize(imageWidth, imageHeight, 'xMidYMid meet');
            this.canvgElement
                .render()
                .then(() => {
                    this.inResize = false;
                    resolve();
                })
                .catch((err) => reject(err));
        });
    }

    image() {
        console.assert(this.canvas);
        return this.canvas;
    }

    makeHitformFunction(ratio) {
        return makeHitformFunction(this.hitform, ratio);
    }
}
