'use strict';

export default class ImageVidaSubComponent {
    constructor() {}

    toJson() {
        console.error('toJson should be overriden');
        return {};
    }

    imageToPdf() {
        console.error('toPdf should be overriden');
    }
}
