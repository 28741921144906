'use strict';

import AbstractVidaComponent from '@/vida/components/abstractvidacomponent.js';
import { computeLocation, computeLocationParams } from '@/vida/components/positionutils.js';
import { generateId } from '@/plugins/utils';

import InternalSvgVidaSubComponent from '@/vida/components/internalsvgsubcomponent.js';
import QrcodeVidaSubComponent from '@/vida/components/qrcodesubcomponent.js';
import PhotoVidaSubComponent from '@/vida/components/photosubcomponent.js';
import InvalidDataError from '@/vida/invaliddataerror.js';

import Konva from 'konva';

export default class ImageVidaComponent extends AbstractVidaComponent {
    static type = 'image';

    constructor(vida, id) {
        super(vida, id);
        this.selected = false;
        this.image = undefined;
        this.type = 'image';
    }

    static randomOptions(image_type, image_use) {
        // image_use == 'background' or 'symbol'
        let random_image_options = {};
        if (image_type === 'internalsvg') random_image_options = InternalSvgVidaSubComponent.randomOptions(image_use);
        else if (image_type === 'internalphoto') random_image_options = PhotoVidaSubComponent.randomOptions(image_use);
        else if (image_type === 'qrcode') random_image_options = QrcodeVidaSubComponent.randomOptions();

        return {
            id: generateId(),
            type: this.type,
            image: random_image_options,
            scale: 20,
            position: { x: 0.5, y: 0.5 },
            angle: 0,
        };
    }

    toJson() {
        if (!this.konvaElement) return {};
        console.assert(Math.abs(this.konvaElement.scaleX() - this.konvaElement.scaleY()) < 0.001);

        const result = {
            id: this.id,
            type: 'image',
            scale: this.scale * this.konvaElement.scaleX(),
            image: this.image.toJson(),
            position: {
                x: this.position.x,
                y: this.position.y,
            },
            angle: this.angle,
            zIndex: this.konvaElement.zIndex(),
        };
        return result;
    }

    toPdf() {
        if (!this.konvaElement) return undefined;
        const { x, y } = computeLocation(this.position, this.documentSize);
        const content = {
            absolutePosition: {
                x: x - this.konvaElement.width() / 2,
                y: y - this.konvaElement.height() / 2,
            },
            width: this.konvaElement.width(),
            height: this.konvaElement.height(),
        };

        let r;
        if (this.image.type === 'internalphoto') {
            r = {
                content: [Object.assign({}, content, { image: this.konvaElement.toDataURL({ mimeType: this.image.mimeType, quality: 1 }) })],
                status: 'ok',
            };
        } else {
            r = {
                content: [Object.assign({}, content, this.image.toPdf())],
                status: 'ok',
            };
        }
        return r;
    }

    select() {
        if (!this.konvaElement) return;
        this.selected = true;
        if (!this.konvaTransformer) {
            this.konvaTransformer = new Konva.Transformer({
                resizeEnabled: true,
                rotateEnabled: true,
                enabledAnchors: ['top-left', 'top-right', 'bottom-left', 'bottom-right'],
                keepRatio: true,
            });
        }
        this.konvaTransformer.nodes([this.konvaElement]);
        this.vida.registerTransformer(this.konvaTransformer);
        this.konvaElement.setDraggable(true);
        this.vida.draw();
    }

    unselect() {
        this.selected = false;
        if (this.konvaElement) this.konvaElement.setDraggable(false);
        if (this.konvaTransformer) this.konvaTransformer.detach();
    }

    destroy() {
        if (this.konvaElement) this.konvaElement.destroy();
    }

    internalUpdate(options, document = null) {
        // if (options.image && !options.image.type) options.image.type = 'internalsvg'
        return new Promise((resolve, reject) => {
            if (this.inPromise) {
                resolve(false);
                return;
            }

            if (!options) {
                resolve(false);
                return;
            }
            if (options.id !== undefined) this.id = options.id;
            if (options.zIndex !== undefined && (this.zIndex === undefined || options.zIndex !== this.zIndex)) {
                this.zIndex = options.zIndex;
            }

            // Pas bon
            console.assert(this.image || (options.image && options.image.type));
            if (!this.image || (options.image && options.image.type && this.image.type !== options.image.type)) {
                if (this.image) {
                    delete this.image;
                    this.image = undefined;
                }
                if (options.image.type === 'internalsvg') {
                    this.image = new InternalSvgVidaSubComponent();
                } else if (options.image.type === 'qrcode') {
                    this.image = new QrcodeVidaSubComponent();
                    // TODO do not recreate image if options changed from internalphoto to urlphoto
                } else if (options.image.type === 'urlphoto') {
                    this.image = new PhotoVidaSubComponent('urlphoto');
                } else if (options.image.type === 'internalphoto') {
                    this.image = new PhotoVidaSubComponent('internalphoto');
                } else {
                    throw new InvalidDataError(`Unknown option ${options.image.type}`);
                }
            }

            this.inPromise = true;
            this.image
                .update(options.image)
                .then(() => {
                    const documentDimensionChanged =
                        !this.documentSize ||
                        (document && (this.documentSize.width !== document.width || this.documentSize.height !== document.height));

                    if (
                        documentDimensionChanged ||
                        this.image.recomputeImage ||
                        (options.scale !== undefined && (this.scale === undefined || this.scale !== options.scale))
                    ) {
                        if (options.scale !== undefined) this.scale = options.scale;
                        this.image.recomputeImage = false;
                        this.image.recomputePosition = true;

                        if (!this.documentSize) this.documentSize = {};
                        if (document && document.width) this.documentSize.width = document.width;
                        if (document && document.height) this.documentSize.height = document.height;
                        const ratioWidth = this.documentSize.width / this.image.originalSize.width;
                        const ratioHeight = this.documentSize.height / this.image.originalSize.height;
                        const ratio = (this.scale / 100) * Math.max(ratioWidth, ratioHeight);
                        const imageWidth = ratio * this.image.originalSize.width;
                        const imageHeight = ratio * this.image.originalSize.height;
                        this.inPromise = true;
                        this.image.makeImage(imageWidth, imageHeight).then(() => {
                            if (this.konvaElement) this.konvaElement.destroy();
                            this.konvaElement = new Konva.Image({ draggable: false, image: this.image.image() });

                            this.konvaElement.on('click', () => {
                                this.vida.selectComponent(this.id);
                            });
                            this.konvaElement.on('dragend', () => {
                                this.adjustPosition();
                            });
                            this.konvaElement.on('transformend', () => {
                                this.adjustSizeAndAngle();
                            });

                            this.vida.registerComponent(this.konvaElement);
                            if (this.selected) {
                                this.unselect();
                                this.select();
                            }
                            this.konvaElement.setAttrs({
                                width: imageWidth,
                                height: imageHeight,
                                scaleX: 1,
                                scaleY: 1,
                                image: this.image.image(),
                                zIndex: this.zIndex,
                                hitFunc: this.image.makeHitformFunction(ratio),
                            });

                            this.inPromise = false;
                            this.recomputeAngle = true;
                            this.internalUpdate(options, document)
                                .then(() => resolve(true))
                                .catch((err) => reject(err));
                        });
                        return;
                    }

                    if (this.recomputeAngle || (options.angle !== undefined && options.angle !== this.angle)) {
                        if (options.angle !== undefined) this.angle = options.angle;
                        this.recomputeAngle = false;
                        this.konvaElement.setRotation(this.angle);
                    }

                    const positionChanged =
                        this.position === undefined ||
                        (options.position !== undefined &&
                            ((options.position.x !== undefined && this.position.x !== options.position.x) ||
                                (options.position.y !== undefined && this.position.y !== options.position.y)));

                    if (!this.position && !options.position) {
                        this.position = { x: 0, y: 0 };
                    }

                    if (this.image.recomputePosition || positionChanged || documentDimensionChanged) {
                        this.image.recomputePosition = false;
                        if (!this.documentSize) this.documentSize = {};
                        if (document && document.width) this.documentSize.width = document.width;
                        if (document && document.height) this.documentSize.height = document.height;
                        if (options.position) {
                            if (this.position === undefined) this.position = {};
                            if (options.position.x) this.position.x = options.position.x;
                            if (options.position.y) this.position.y = options.position.y;
                        }

                        const { x, y } = computeLocation(this.position, this.documentSize);
                        this.konvaElement.setAttrs({
                            x,
                            y,
                            offsetX: this.konvaElement.width() / 2,
                            offsetY: this.konvaElement.height() / 2,
                            zIndex: this.zIndex,
                        });

                        this.vida.draw();
                    }

                    if (this.zIndex !== this.konvaElement.zIndex()) {
                        // https://konvajs.org/docs/groups_and_layers/zIndex.html#page-title
                        this.konvaElement.zIndex(this.zIndex);
                        this.vida.draw();
                    }
                    this.inPromise = false;
                    resolve(false);
                })
                .catch((err) => reject(err));
        });
    }

    adjustPosition() {
        if (!this.konvaElement) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.vida.onComponentUpdated(this.id);
    }

    adjustSizeAndAngle() {
        if (!this.konvaElement) return;
        this.position = computeLocationParams({ x: this.konvaElement.x(), y: this.konvaElement.y() }, this.documentSize);
        this.angle = this.konvaElement.rotation();
        console.assert(Math.abs(this.konvaElement.scaleX() - this.konvaElement.scaleY()) < 0.001);
        if (this.konvaElement.scaleX() !== 1) this.update({ scale: this.scale * this.konvaElement.scaleX() }, this.vida.documentParams());
        this.vida.onComponentUpdated(this.id);
    }
}
