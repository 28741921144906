<template>
    <div class="input-components image-input-components">
        <template v-if="!isUndefined">
            <component-toolbar
                @remove="removeComponent"
                @forward="++zIndexValue"
                @backward="--zIndexValue"
                @duplicate="$emit('duplicate')"
                :forwardEnabled="options.zIndex < nbComponents"
                :backwardEnabled="options.zIndex > 1" />

            <picker-container :label="$t('COMMON.IMAGE')">
                <template v-slot:pickers>
                    <image-picker v-model="imageValue" />
                </template>
            </picker-container>

            <picker-container :label="$t('COMMON.SIZE')">
                <template v-slot:pickers>
                    <number-picker :min="0" :max="200" v-model="scaleValue" unit="%" :prepend="$t('COMMON.SMALL')" :append="$t('COMMON.BIG')" />
                </template>
            </picker-container>

            <position-picker :width="width" :height="height" v-model="positionValue" />

            <picker-container :label="$t('COMMON.ANGLE')">
                <template v-slot:pickers>
                    <number-picker :min="-180" :max="180" v-model="angleValue" unit="°" :prepend="'-180°'" :append="'+180°'" />
                </template>
            </picker-container>
        </template>
    </div>
</template>

<script>
import { fonts } from '@/plugins/font-loader';
import ImagePicker from '@/components/pickers/ImagePicker.vue';
import PositionPicker from '@/components/pickers/PositionPicker.vue';
import NumberPicker from '@/components/pickers/NumberPicker.vue';
import PickerContainer from '@/components/pickers/PickerContainer.vue';
import ComponentToolbar from '@/components/pickers/ComponentToolbar.vue';

export default {
    name: 'image-input-component',
    components: { ImagePicker, PositionPicker, NumberPicker, PickerContainer, ComponentToolbar },
    props: {
        width: Number,
        height: Number,
        fieldLabel: String,
        type: String, // internalsvg or qrcode
        // TODO add values validators
        options: {
            image: Object,
            scale: { type: Number, default: 10 },
            position: {
                x: { type: Number, default: 1 },
                y: { type: Number, default: 1 },
            },
            angle: { type: Number, default: 0 },
            zIndex: { type: Number, default: 1 },
        },
        nbComponents: { type: Number, defalut: 1 },
    },
    data: () => ({
        fonts,
    }),
    computed: {
        scaleValue: {
            get() {
                return this.options.scale;
            },
            set(newValue) {
                if (newValue !== this.options.scale) this.$emit('update', { scale: newValue });
            },
        },
        zIndexValue: {
            get() {
                return this.options.zIndex;
            },
            set(newValue) {
                newValue = Math.max(newValue, 1);
                newValue = Math.min(newValue, this.nbComponents);
                if (newValue === this.options.zIndex) return;
                this.$emit('update', { zIndex: newValue });
                this.options.zIndex = newValue;
            },
        },
        positionValue: {
            get() {
                return this.options.position;
            },
            set(newValue) {
                if (newValue.x !== this.options.position.x || newValue.y !== this.options.position.y) this.$emit('update', { position: newValue });
            },
        },
        imageValue: {
            get: function () {
                return this.options.image;
            },
            set: function (newValue) {
                if (newValue !== this.imageValue) this.$emit('update', { image: newValue });
            },
        },
        angleValue: {
            get: function () {
                return this.options.angle;
            },
            set: function (newValue) {
                if (newValue !== this.options.angle) this.$emit('update', { angle: newValue });
            },
        },
        isUndefined: function () {
            return Object.keys(this.options).length === 1;
        },
    },
    methods: {
        removeComponent() {
            // TODO confirm box ? Oui en attendant le undo/redo
            this.$emit('remove');
        },
    },
};
</script>
