'use strict';

export default class AbstractVidaComponent {
    constructor(vida) {
        this.vida = vida;
        this.vida.draw();
        this.id = undefined;
    }

    select() {
        console.warning('select should be overriden');
    }

    unselect() {
        console.warning('unselect should be overriden');
    }

    // Check sur les options possibles.
    // Mise à jour de l'objet selon les options avec l'utilisation d'un cache.
    update(options, document) {
        this.pushHistory({ options, document });
        return this.internalUpdate(options, document);
    }

    internalUpdate() {
        console.warning('Internal update should be overriden');
    }

    destroy() {
        console.warning('Destroy should be overriden');
    }

    remove() {
        if (this.konvaTransformer) this.konvaTransformer.destroy();
        if (this.konvaElement) this.konvaElement.destroy();
        this.vida.draw();
    }

    // Export des options. Utilisable pour un composant d'input par ex. ou pour la sauvegarde des données
    toJson() {
        console.error('toJson should be overriden');
        return {};
    }

    toPdf() {
        console.error('toPdf should be overriden');
        return {
            content: [],
            status: 'unsupported_option',
        };
    }

    pushHistory(modification) {
        if (!this.history) this.history = [];
        this.history.push(modification);
    }

    popHistory() {
        const modification = this.history.back();
        this.history.pop();
        return modification;
    }
}
