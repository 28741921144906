<template>
    <picker-container :label="$t('COMMON.POSITION')">
        <template v-slot:pickers>
            <number-picker :min="minX" :max="maxX" v-model="xValue" unit="px" :prepend="$t('COMMON.LEFT')" :append="$t('COMMON.RIGHT')" />
            <div style="margin: 12px; width: 100%; height: 1px"></div>
            <number-picker :min="minY" :max="maxY" v-model="yValue" unit="px" :prepend="$t('COMMON.TOP')" :append="$t('COMMON.BOTTOM')" />
        </template>
    </picker-container>
</template>

<script>
import NumberPicker from '@/components/pickers/NumberPicker.vue';
import PickerContainer from '@/components/pickers/PickerContainer.vue';

export default {
    name: 'position-picker',
    components: { NumberPicker, PickerContainer },
    props: {
        width: Number,
        height: Number,
        value: {
            x: Number,
            y: Number,
        },
    },
    computed: {
        xValue: {
            get: function () {
                return this.fromPercent(this.value.x, this.width);
            },
            set: function (newValue) {
                if (newValue == this.value.x) return;
                this.$emit('input', { x: this.toPercent(newValue, this.width) });
            },
        },
        yValue: {
            get: function () {
                return this.fromPercent(this.value.y, this.height);
            },
            set: function (newValue) {
                if (newValue == this.value.y) return;
                this.$emit('input', { y: this.toPercent(newValue, this.height) });
            },
        },
        minX: function () {
            return Math.min(-this.width / 2, this.xValue);
        },
        maxX: function () {
            return Math.max(this.width / 2, this.xValue);
        },
        minY: function () {
            return Math.min(-this.height / 2, this.yValue);
        },
        maxY: function () {
            return Math.max(this.height / 2, this.yValue);
        },
    },
    methods: {
        toPercent(value, dimension) {
            return value / dimension + 0.5;
        },
        fromPercent(value, dimension) {
            return (value - 0.5) * dimension;
        },
    },
};
</script>
